// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

// var $ = require('jquery');
// var Link = require('../_modules/link/link');

$(function() {
  // new Link(); // Activate Link modules logic

  $('#mainSlider .slide').slick({
  	autoplay: true,
    fade: true,
  	dots: true,
  	arrows: false
  });

  if ( $("body").hasClass("home") ) {
    // Select all links with hashes
    $('.main-nav a[href*="#"]')
      // Remove links that don't actually link to anything
      .not('[href="#"]')
      .not('[href="#0"]')
      .click(function(event) {
        // Make sure this.hash has a value before overriding default behavior
        var target = this.hash;

        event.preventDefault();

        var navOffset = $('header').height();

        if ($(window).width() < 992) {
          $("#mainNav").hide();
        }

        return $('html, body').animate({
          scrollTop: $(this.hash).offset().top - navOffset
        }, 800, function() {
          return window.history.pushState(null, null, target);
        });
      });
    }

    $("#map-container .btn-1").hover(function(event) {
      var target = $(this).attr('data-target');
      $("#map-container .btn-1").removeClass("active");
      $(this).addClass("active");
      $("#map-container .map").removeClass().addClass("map").addClass(target);
    });

  function submitForm() {
    var form_data = {
      name    : ($('#form_name').val()) ? $('#form_name').val() : "",
      phone   : ($('#form_phone').val()) ? $('#form_phone').val() : "",
      email   : ($('#form_email').val()) ? $('#form_email').val() : "",
      message : ($('#form_message').val()) ? $('#form_message').val() : ""
    };

    $.ajax({
      url:"https://docs.google.com/forms/u/4/d/e/1FAIpQLSe75WUuR-NnULekcF0cNTiN9UDkpKe88y1AqpQ6-um2PFCAQQ/formResponse",
      data:{
        "entry.1644654519": form_data.name,
        "entry.1220061560": form_data.phone,
        "entry.856771336" : form_data.email,
        "entry.931823881" : form_data.message
      },
      type:"POST",
      dataType:"xml",
      statusCode: {
        0:   function() {
          // window.location.replace("thankyou.html");
        },
        200: function() {
          // window.location.replace("thankyou.html");

        }
      }
    });
  }
  $("#submit-form").click(function(event) {
    event.preventDefault();
    submitForm();
  });

  $(".toggle-btn").click(function() {
    $(".main-nav").toggle();
  });

  function setSummaryInfoHeight() {
    if ($(".summary-block").width() > 992) {
      $(".summary-block .info").height($(".summary-img").outerHeight() - 55);
    }
  }

  setSummaryInfoHeight();

  $(window).resize(function() {
    setSummaryInfoHeight();
  });
});
